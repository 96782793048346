import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/logo-h.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmButton = _resolveComponent("DmButton")
  const _component_DmWrapper = _resolveComponent("DmWrapper")

  return (_openBlock(), _createBlock(_component_DmWrapper, {
    class: _ctx.$bem({}),
    "data-test": "home-page"
  }, {
    default: _withCtx(() => [
      _createVNode("img", {
        src: _imports_0,
        class: _ctx.$bem({e: 'logo'})
      }, null, 2),
      _createVNode("h1", {
        class: _ctx.$bem({e: 'title'})
      }, null, 2),
      _createVNode("h2", {
        class: _ctx.$bem({e: 'subtitle'})
      }, null, 2),
      _createVNode(_component_DmButton, {
        color: "primary",
        size: "lg",
        dark: "",
        elevated: "",
        to: _ctx.loginRoute,
        class: _ctx.$bem({e: 'login-button'}),
        "data-test": "go-to-app-button"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.isLogged ? _ctx.$t('home.goToApp') : _ctx.$t('home.login')), 1)
        ]),
        _: 1
      }, 8, ["to", "class"])
    ]),
    _: 1
  }, 8, ["class"]))
}