
import { defineComponent } from 'vue';
import {
  DmWrapper,
  DmButton
} from '@dobrymechanik/vue-ui';
import { RouteName } from '@/router/models';
import { LocalStorageKey } from '@/models/storage';

export default defineComponent({
  name: 'Home',
  components: {
    DmWrapper,
    DmButton
  },
  data () {
    return {
      loginRoute: {
        name: RouteName.LOGIN
      },
      isLogged: localStorage.getItem(LocalStorageKey.IS_LOGGED) === '1'
    };
  }
});
